@import 'variables';

mat-radio-button {
  color: $neutral-dark;
  --mdc-radio-selected-icon-color: #{$neutral-medium};
  --mdc-radio-unselected-icon-color: #{$neutral-medium};

  .mdc-label {
    color: $main-color-15;
    cursor: pointer;
  }

  &:hover {
    color: $neutral-dark;
    --mdc-radio-selected-icon-color: #{$neutral-dark};
    --mdc-radio-unselected-icon-color: #{$neutral-dark};
  }

  .mdc-radio--disabled,
  &:disabled {
    opacity: 0.3;
  }
}

.mat-mdc-radio-touch-target {
  z-index: 1000;
}
