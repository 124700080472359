@import '@ng-select/ng-select/themes/default.theme.css';
@import 'variables';
@import 'mixins/placeholder';

.ng-select .ng-select-container .ng-value-container .ng-placeholder {
  @include placeholder;
}

.ng-dropdown-panel {
  width: auto !important;
  min-width: 100%;
  margin-top: 0;
  border-style: none;
  border-radius: $border-hg;
  background-color: $main-color-14;
  box-shadow: $block-box-shadow;
  font-size: $font-size-md;
  letter-spacing: $letter-spacing-sm;

  &.ng-select-bottom {
    margin-top: 0;
  }

  .ng-dropdown-header {
    display: none;
    padding: 12px 16px;
    border-style: none;
  }

  .ng-dropdown-panel-items {
    padding: 4px 0;

    .ng-option {
      padding: $space-xsm $space-lg;
      color: $text-base !important;
      font-weight: normal !important;
      font-size: $font-size-md;
      line-height: 20px;
    }

    .ng-option.ng-option-child {
      padding-left: 16px;
    }

    .ng-option.ng-option-marked {
      background-color: $neutral-lightest !important;
    }

    .ng-option.ng-option-selected {
      background-color: $primary-lightest !important;
      color: $primary-dark !important;
    }

    .ng-option.not-found {
      color: $main-color-18 !important;
      text-align: center;
    }
  }

  .ng-optgroup {
    padding: 0 0 0 $space-sm !important;

    &.ng-option-selected {
      background-color: transparent !important;
    }

    .ng-optgroup-label {
      margin-top: $space-xs;
      color: $text-base-75 !important;
      font-weight: normal !important;
      font-size: $font-size-sm;
      line-height: 20px;
    }
  }

  .ng-dropdown-panel-items {
    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 2px;
      background: $main-color-23;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: $main-color-19;
    }
  }
}
