// TODO: Исправить баг с относительным импортом (минорно)
@import '../../../node_modules/driver.js/dist/driver.css';
@import 'mixins';
@import 'variables';

// https://driverjs.com/docs/theming

.onboarding-helper {
  &.driver-popover {
    border-radius: 4px;
    padding: 24px;
  }

  /* Title and description */
  .driver-popover-title {
    @include fontify(20, 500, $text-base);
    font-family: $primary-font;
  }

  .driver-popover-description {
    @include fontify(16, 400, $text-base-75);
    font-family: $primary-font;
  }

  .driver-popover-navigation-btns {
    gap: 8px;
  }

  .driver-popover-close-btn {
    display: none !important;
  }

  .driver-popover-prev-btn {
    @include reset;
    @include button-common;
    @include button-outlined;
    @include medium-button;
  }

  .driver-popover-next-btn {
    @include reset;
    @include button-common;
    @include button-primary;
    @include medium-button;
  }
}
