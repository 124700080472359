// Entry point for global styles.
// It's a common place to override framework classes or add global classes that will work everywhere
// DO NOT import these files into components
// DO NOT declare variables or mixins here (use files one level up)

// Angular material theme (should be first)
@import 'material';
@import 'fonts';
@import 'body';

@import 'checkbox';
@import 'chips';
@import 'grid';
@import 'input';
@import 'modal';
@import 'progress';
@import 'select';
@import 'toast';
@import 'tooltip';
@import 'loader';
@import 'checkbox-group';
@import 'color';
@import 'radio-button';
@import 'margin-padding';
@import 'utilities';
@import 'text';
@import 'highlight';
@import 'info-message';
@import 'mat-slide-toggle';
@import 'mat-accordion';
@import 'settings-grid-wrapper';
@import 'context-menu';
@import 'menu';
@import 'typography';
@import 'tabs';
@import 'driverjs';
@import 'link';
@import 'disable';
@import 'squares';

.display-flex {
  display: flex;

  &.justify-space-between {
    justify-content: space-between;
  }

  &.align-center {
    align-items: center;
  }
}

.justify-center {
  display: flex;
  justify-content: center;
}

[role='button'] {
  cursor: pointer;
}

.pointer {
  cursor: pointer;
}

.ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
