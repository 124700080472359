@import 'variables';

.mat-mdc-slide-toggle {
  --mat-slide-toggle-label-text-size: #{$font-size-md};
  --mdc-switch-unselected-track-color: #{$main-color-17};
  --mdc-switch-selected-track-color: #{$main-color-27};
  --mdc-elevation-overlay-color: #{$main-color-14};

  &.mat-mdc-slide-toggle-checked {
    --mdc-elevation-overlay-color: #{$primary};
  }

  .mdc-switch {
    margin-right: 16px;
  }

  .mdc-label {
    margin-right: 16px;
    color: $text-base;

    .mat-slide-toggle-thumb {
      background-color: $main-color-14;
    }
  }
}
