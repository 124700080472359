@import 'variables';

@mixin button-common {
  pointer-events: all;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 0;
  border-radius: 2px;
  outline: none;
  font-weight: 400;
  font-style: normal;
  font-family: $primary-font;
  white-space: nowrap;
  cursor: pointer;

  &:disabled {
    opacity: 0.4;
  }
}

@mixin button-primary {
  background-color: $primary;
  color: $main-color-14;

  &:hover:not(:disabled) {
    background-color: $primary-light;
  }

  &.active:not(:disabled),
  &:active:not(:disabled),
  &:focus:not(:disabled) {
    background-color: $primary-dark;
  }
}

@mixin button-outlined {
  border: 1px solid $neutral-light;
  background-color: $main-color-14;
  color: $text-base-75;

  &:focus:not(:disabled) {
    background-color: $main-color-14;
  }

  &:hover:not(:disabled) {
    border-color: $neutral-medium;
    background-color: $neutral-lightest;
  }

  &.active:not(:disabled),
  &:active:not(:disabled) {
    border-color: $primary-dark;
    background-color: $primary-lightest;
    color: $primary-dark;
  }
}

@mixin small-button {
  height: 24px;
  padding: 0 8px;
  font-size: $font-size-sm;
}

@mixin medium-button {
  height: 32px;
  padding: 0 16px;
  font-size: $font-size-md;
}

@mixin large-button {
  height: 40px;
  padding: 0 24px;
  font-weight: 500;
  font-size: $font-size-md;
  text-transform: uppercase;
}
