@mixin flexify($direction: row, $justify: space-between, $align: center, $gap: null) {
  align-items: $align;
  display: flex !important;
  flex-direction: $direction;
  justify-content: $justify;

  @if ($gap) {
    gap: $gap;
  }
}
