@import 'variables';

@keyframes progress-animation {
  0% {
    left: -5%;
  }

  100% {
    left: 100%;
  }
}

.mdc-linear-progress__bar-inner {
  position: relative;
  overflow: hidden;
  background-color: $main-color-6;
  transition-duration: 250ms !important;
}

$progress-bg: linear-gradient(90deg, $main-color-6 0%, #c9e0f7 16.67%, $main-color-6 26.04%, $main-color-6 99.92%);

.mdc-linear-progress__bar-inner::after {
  position: relative;
  width: 20%;
  background: $progress-bg 0 0;
  animation: progress-animation 1.5s infinite !important;
}
