@import 'variables';

.tab-bar {
  .mdc-tab {
    opacity: 1;
    border: 1px solid $main-color-14;
    background: $primary-lightest;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;

    &::before {
      display: none !important;
    }
  }

  &:not(.mdc-tab--active) {
    .mdc-tab {
      border-bottom: 1px solid $neutral-light;
    }
  }

  .mdc-tab--active {
    background-color: $main-color-14;
    border-color: $neutral-light $neutral-light $main-color-14 $neutral-light;
    border-width: 1px;
    border-style: solid;
    top: 1px;
    position: relative;
  }

  mat-tab-body {
    border-width: 1px;
    border-style: solid;
    border-color: $main-color-14 $neutral-light $neutral-light $neutral-light;
  }

  .mat-tab-list {
    border-bottom: 1px solid $neutral-light;
  }

  .mdc-tab-indicator__content {
    display: none;
  }

  .mdc-tab__ripple {
    display: none;
  }
}

.mat-mdc-tab-group,
.mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #{$main-color-14};
}
