@use '@angular/material' as mat;
@import 'theme';
@import 'variables';

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.core-theme($verifika-theme);
@include mat.dialog-theme($verifika-theme);
@include mat.progress-bar-theme($verifika-theme);
@include mat.snack-bar-theme($verifika-theme);
@include mat.slide-toggle-theme($verifika-theme);
@include mat.chips-theme($verifika-theme);
@include mat.input-theme($verifika-theme);
@include mat.tabs-theme($verifika-theme);
@include mat.form-field-theme($verifika-theme);
@include mat.strong-focus-indicators-theme($verifika-theme);

:root {
  --mat-form-field-container-vertical-padding: 0;
  --mat-form-field-container-vertical-padding: 0;
  --mdc-filled-text-field-container-color: transparent;
  --mat-form-field-state-layer-color: transparent;
  --mdc-filled-text-field-container-shape: 0;
  --mdc-filled-text-field-container-shape: 0;
  --mat-form-field-container-height: 40px;
  --mat-autocomplete-container-elevation-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-autocomplete-background-color: #{$main-color-14};
  --mat-autocomplete-container-shape: #{$border-hg};
  --mdc-checkbox-state-layer-size: 0;
}
