.ag-layout-print {
    &.ag-body-viewport {
        flex: none;
    }

    &.ag-root-wrapper {
        display: inline-flex;
    }

    .ag-center-cols-clipper {
        min-width: 100%;
    }

    .ag-body-horizontal-scroll {
        display: none;
    }
    &.ag-force-vertical-scroll {
        overflow-y: visible !important;
    }
}

@media print {
    .ag-root-wrapper.ag-layout-print {
        display: table;

        .ag-root-wrapper-body,
        .ag-root,
        .ag-body-viewport,
        .ag-center-cols-container,
        .ag-center-cols-viewport,
        .ag-center-cols-clipper,
        .ag-body-horizontal-scroll-viewport,
        .ag-virtual-list-viewport {
            // Need auto height because 100% height elements with overflow hidden cause printing issues in Edge
            height: auto !important;
            // Overflow hidden, because otherwise scroll bars print in IE
            overflow: hidden !important;
            // flex elements cause printing issues in Firefox
            // https://bugzilla.mozilla.org/show_bug.cgi?id=939897
            display: block !important;
        }
        .ag-row, .ag-cell {
            break-inside: avoid;
        }
    }
}