@import 'variables';
@import 'mixins/index';

.context-menu {
  background: $main-color-14;
  border-radius: 4px;
  box-shadow: 0 4px 5px 0 rgba(0, 46, 102, 0.2), 0 3px 14px 0 rgba(0, 46, 102, 0.12);
  min-height: 40px;

  min-width: 200px;
  position: fixed;

  // NOTE: z-index should be bigger than modal z-index
  z-index: 1001;
}

.divider {
  background: $main-color-25;
  height: 1px;
  margin: 10px 0;
  width: 100%;
}

.context-menu-item {
  @include fontify(12, 400);

  cursor: pointer;
  padding: 6px 8px;

  &.disabled {
    @include font-opacity($main-color-15, 0.5);
    cursor: not-allowed;
  }
}
