@import 'variables';

.square {
  width: 8px;
  height: 8px;
  display: inline-block;

  &.red {
    background: $semantic-error;
  }

  &.gray {
    background: $neutral-medium;
  }
}
