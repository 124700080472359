@import 'variables';
@import 'mixins';

.mat-mdc-menu-panel.mat-autocomplete-panel,
.mat-mdc-menu-panel.menu-panel {
  max-width: 300px;
  min-height: 0;
  border-radius: $border-hg;
  background-color: $main-color-14;
  box-shadow: $block-box-shadow;
  letter-spacing: $letter-spacing-sm;

  .mat-mdc-menu-content {
    padding: 4px 0;
  }
}

.menu-panel.mat-mdc-menu-panel.dropdown-actions {
  max-width: 340px;
}

.autocomplete-panel,
.dropdown-panel {
  .list-item {
    height: 32px !important;
  }
}

// styles for dropdown and select items
.mat-mdc-option.list-item,
.mat-mdc-menu-item.list-item,
.list-item {
  @include flexify(row, flex-start);
  box-sizing: border-box;
  height: 40px;
  padding: $space-xsm $space-lg;
  color: $text-base;
  font-size: $font-size-md;
  line-height: 20px;
  cursor: pointer;

  &:not(.disabled) {
    &:hover,
    &:focus {
      background-color: $neutral-lightest;
    }

    &:active,
    &.active {
      background-color: $primary-lightest;
      color: $primary-dark;

      .list-item__icon {
        color: $primary-dark;
      }
    }
  }

  &.disabled {
    opacity: 0.5;
    cursor: default;
  }

  &__icon {
    display: inline-block !important;
    padding-right: $space-sm;
    color: $text-base-50;
  }

  &--danger {
    color: $error-color-1;

    // TODO: убрать бэм
    .list-item__icon {
      color: $error-color-1;
    }
  }
}

.list-item-group {
  height: 24px;
  padding: $space-xs 0 0 $space-sm;
  color: $text-base-75;
  font-size: $font-size-sm;
  line-height: 20px;
}

.menu-separator {
  margin: 8px;
  height: 1px;
  border: 0;
  background: $main-color-31;
}

.mat-mdc-menu-item-text {
  width: 100%;
}
