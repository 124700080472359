@import 'mixins';
@import 'variables';

.mdc-evolution-chip-set__chips {
  @include flexify();
}

.mat-mdc-standard-chip {
  --mdc-chip-elevated-container-color: #{$neutral-lightest};
}

.mat-mdc-chip-remove {
  opacity: 1 !important;
}

.mat-mdc-chip.cdk-focused .mat-mdc-chip-focus-overlay {
  opacity: 0 !important;
}
